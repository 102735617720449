



















import { Component, Vue } from 'vue-property-decorator';
import OrganisationMemberList from '@/modules/organisation/components/OrganisationMemberList.vue';

@Component({
  name: 'team',

  components: {
    OrganisationMemberList,
  },
  layout: 'AppMain',
})
export default class Team extends Vue {}
